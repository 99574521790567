// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/navigation/SideMenuItem.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/navigation/SideMenuItem.tsx");
  import.meta.hot.lastModified = "1728642254856.8586";
}
// REMIX HMR END

import { usePathName } from "~/hooks/usePathName";
import { cn } from "~/utils/cn";
import { LinkButton } from "../primitives/Buttons";
export function SideMenuItem({
  icon,
  activeIconColor,
  inactiveIconColor,
  trailingIcon,
  trailingIconClassName,
  name,
  to,
  badge,
  target,
  subItem = false
}) {
  _s();
  const pathName = usePathName();
  const isActive = pathName === to;
  return <LinkButton variant={subItem ? "small-menu-sub-item" : "small-menu-item"} fullWidth textAlignLeft LeadingIcon={icon} leadingIconClassName={isActive ? activeIconColor : inactiveIconColor ?? "text-text-dimmed"} TrailingIcon={trailingIcon} trailingIconClassName={trailingIconClassName} to={to} target={target} className={cn("text-text-bright group-hover:bg-charcoal-750 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0", subItem ? "text-text-dimmed" : "", isActive ? "bg-tertiary text-text-bright" : "group-hover:text-text-bright")}>
      <div className="flex w-full items-center justify-between">
        {name}
        <div className="flex items-center gap-1">
          {badge !== undefined && <MenuCount count={badge} />}
        </div>
      </div>
    </LinkButton>;
}
_s(SideMenuItem, "NMaPwz5Mfru/Lvs73c7HWspDX+A=", false, function () {
  return [usePathName];
});
_c = SideMenuItem;
export function MenuCount({
  count
}) {
  return <div className="rounded-full bg-charcoal-900 px-2 py-1 text-xxs uppercase tracking-wider text-text-dimmed">
      {count}
    </div>;
}
_c2 = MenuCount;
var _c, _c2;
$RefreshReg$(_c, "SideMenuItem");
$RefreshReg$(_c2, "MenuCount");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;