// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/navigation/HelpAndFeedbackPopover.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/navigation/HelpAndFeedbackPopover.tsx");
  import.meta.hot.lastModified = "1732021079342.8347";
}
// REMIX HMR END

import { ArrowUpRightIcon, CalendarDaysIcon, ChatBubbleLeftEllipsisIcon, EnvelopeIcon, LightBulbIcon, SignalIcon } from "@heroicons/react/20/solid";
import { DiscordIcon, SlackIcon } from "@trigger.dev/companyicons";
import { Fragment, useState } from "react";
import { useCurrentPlan } from "~/routes/_app.orgs.$organizationSlug/route";
import { Feedback } from "../Feedback";
import { StepContentContainer } from "../StepContentContainer";
import { Button } from "../primitives/Buttons";
import { ClipboardField } from "../primitives/ClipboardField";
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from "../primitives/Dialog";
import { Icon } from "../primitives/Icon";
import { Paragraph } from "../primitives/Paragraph";
import { Popover, PopoverContent, PopoverSideMenuTrigger } from "../primitives/Popover";
import { StepNumber } from "../primitives/StepNumber";
import { MenuCount, SideMenuItem } from "./SideMenuItem";
export function HelpAndFeedback() {
  _s();
  const [isHelpMenuOpen, setHelpMenuOpen] = useState(false);
  const currentPlan = useCurrentPlan();
  return <Popover onOpenChange={open => setHelpMenuOpen(open)}>
      <PopoverSideMenuTrigger isOpen={isHelpMenuOpen} shortcut={{
      key: "h"
    }}>
        <div className="flex items-center gap-1.5">
          <ChatBubbleLeftEllipsisIcon className="size-4 text-success" />
          Help & Feedback
        </div>
      </PopoverSideMenuTrigger>
      <PopoverContent className="min-w-[14rem] divide-y divide-grid-bright overflow-y-auto p-0 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-charcoal-600" align="start">
        <Fragment>
          <div className="flex flex-col gap-1 p-1">
            <SideMenuItem name="Documentation" icon="docs" trailingIcon={ArrowUpRightIcon} trailingIconClassName="text-text-dimmed" inactiveIconColor="text-green-500" activeIconColor="text-green-500" to="https://trigger.dev/docs" data-action="documentation" target="_blank" />
          </div>
          <div className="flex flex-col gap-1 p-1">
            <SideMenuItem name="Status" icon={SignalIcon} trailingIcon={ArrowUpRightIcon} trailingIconClassName="text-text-dimmed" inactiveIconColor="text-green-500" activeIconColor="text-green-500" to="https://status.trigger.dev/" data-action="status" target="_blank" />
            <SideMenuItem name="Suggest a feature" icon={LightBulbIcon} trailingIcon={ArrowUpRightIcon} trailingIconClassName="text-text-dimmed" inactiveIconColor="text-sun-500" activeIconColor="text-sun-500" to="https://feedback.trigger.dev/" data-action="suggest-a-feature" target="_blank" />
            <SideMenuItem name="Changelog" icon="star" trailingIcon={ArrowUpRightIcon} trailingIconClassName="text-text-dimmed" inactiveIconColor="text-sun-500" activeIconColor="text-sun-500" to="https://trigger.dev/changelog" data-action="changelog" target="_blank" />
          </div>
          <div className="flex flex-col gap-1 p-1">
            <Paragraph className="pb-1 pl-1.5 pt-1.5 text-xs">Need help?</Paragraph>
            {currentPlan?.v3Subscription?.plan?.limits.support === "slack" && <div>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant="small-menu-item" LeadingIcon={SlackIcon} data-action="join-our-slack" fullWidth textAlignLeft>
                      <div className="flex w-full items-center justify-between">
                        <span className="text-text-bright">Join our Slack…</span>
                        <MenuCount count="PRO" />
                      </div>
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>Join our Slack</DialogHeader>
                    <div className="mt-2 flex flex-col gap-4">
                      <div className="flex items-center gap-4">
                        <Icon icon={SlackIcon} className="h-10 w-10 min-w-[2.5rem]" />
                        <Paragraph variant="base/bright">
                          As a subscriber, you have access to a dedicated Slack channel for 1-to-1
                          support with the Trigger.dev team.
                        </Paragraph>
                      </div>
                      <hr className="border-charcoal-800" />
                      <div>
                        <StepNumber stepNumber="1" title="Email us" />
                        <StepContentContainer>
                          <Paragraph>
                            Send us an email to this address from your Trigger.dev account email
                            address:
                            <ClipboardField variant="secondary/medium" value="priority-support@trigger.dev" className="my-2" />
                          </Paragraph>
                        </StepContentContainer>
                        <StepNumber stepNumber="2" title="Look out for an invite from Slack" />
                        <StepContentContainer>
                          <Paragraph>
                            As soon as we can, we'll setup a Slack Connect channel and say hello!
                          </Paragraph>
                        </StepContentContainer>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>}
            <SideMenuItem name="Ask in our Discord" icon={DiscordIcon} trailingIcon={ArrowUpRightIcon} trailingIconClassName="text-text-dimmed" to="https://trigger.dev/discord" data-action="join our discord" target="_blank" />
            <SideMenuItem name="Book a 15 min call" icon={CalendarDaysIcon} trailingIcon={ArrowUpRightIcon} trailingIconClassName="text-text-dimmed" inactiveIconColor="text-rose-500" activeIconColor="text-rose-500" to="https://cal.com/team/triggerdotdev/founders-call" data-action="book-a-call" target="_blank" />
            <Feedback button={<Button variant="small-menu-item" LeadingIcon={EnvelopeIcon} leadingIconClassName="text-blue-500" data-action="contact-us" fullWidth textAlignLeft>
                  Contact us…
                </Button>} />
          </div>
        </Fragment>
      </PopoverContent>
    </Popover>;
}
_s(HelpAndFeedback, "nkM+VMW3nojGQV8U3i7HSmHMGoE=", false, function () {
  return [useCurrentPlan];
});
_c = HelpAndFeedback;
var _c;
$RefreshReg$(_c, "HelpAndFeedback");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;